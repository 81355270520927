export const SidebarData = [
  "ABO Incompatibility",
  "Acid Base Imbalance",
  "Acute Phase Reactant",
  "Adrenal gland function",
  "AIDS",
  "Allergy",
  "Alzheimer's Disease",
  "Amyloidosis",
  "Anemia",
  "Anemia - Diagnosis",
  "Antenatal Health Check Up",
  "Arthritis",
  "Autoimmune disorders",
  "Autoimmune disorders- Skin",
  "Autoimmune Hepatitis",
  "Blood Cancer",
  "Blood Infections",
  "Bone",
  "Bone & Mineral Metabolism",
  "Bone Function",
  "Cancer",
  "Cancer Monitoring",
  "Cancer Screen",
  "Cardiac disorders",
  "Celiac Disease",
  "Central  Nervous System Disorders",
  "Central Nervous System disease",
  "Cirrhosis",
  "Clotting Disorders",
  "Complement system Function",
  "Congenital Enzyme Deficiency",
  "Congenital Metabolic Disorders",
  "Connective Tissue Disorders",
  "Coronary Artery Disease",
  "Dengue",
  "Diabetes",
  "Diabetes insipidus",
  "Dialysis",
  "Dialysis Adequacy",
  "DIC",
  "Diptheria",
  "Disease Association",
  "Disorder of Bone",
  "Disorder of Growth",
  "Disorders of Bone",
  "Disorders of Central Nervous System",
  "Disorders of Coagulation",
  "Disorders of Complement system",
  "Disorders of Endocrine System",
  "Disorders of Eyes",
  "Disorders of Gastointestinal system",
  "Disorders of Gastrointestinal system",
  "Disorders of Gastrointestinal Tract",
  "Disorders of Growth",
  "Disorders of Kidney",
  "Disorders of Muscle",
  "Disorders of Nervous System",
  "Disorders of Nutrition",
  "Disorders of Reproductive system",
  "Disorders of Skin",
  "Drug  abuse",
  "Drug Sensitivity",
  "Drugs of Abuse",
  "Effusion",
  "Electrolyte Imbalance",
  "Enzyme deficiency",
  "Establishing Paternity",
  "Ewing's Sarcoma",
  "Fever",
  "Filariasis",
  "For Affymetrix chip only",
  "Gastrointestinal Infection",
  "Gaucher  Disease",
  "Genetic Disorder",
  "Genetic Disorders",
  "Gestational Diabetes",
  "GIST",
  "Glomerulonephritis",
  "Gluten sensitivity",
  "Gout",
  "Gynecological Disorders",
  "Health Checkup",
  "Heart Diseases",
  "Hematological Disorders",
  "Hematological Function",
  "Hematolymphoid Tumors",
  "Hematuria",
  "Hemolytic Anemia",
  "Hemophilia",
  "Histiocytic Lesions",
  "Hormone disorder",
  "Hormone Function",
  "Hypercalcemia",
  "Hyperparathyroidism",
  "Hypertension",
  "Hyperviscosity syndrome",
  "Identification of crystals",
  "Immunization Status",
  "Immunization status- DPT",
  "Immunodeficiency",
  "Immunodeficiency disorders",
  "Immunodeficiency states",
  "Inborn errors of metabolism",
  "Infections",
  "Infectious Mononucleosis",
  "Infertility",
  "Inflammatory Diseases",
  "Kidney",
  "Kidney Disease",
  "Kidney Diseases",
  "Kidney injury",
  "Kidney Stone",
  "Leprosy",
  "Leukemia",
  "Lipid function",
  "Lipomatous lesions",
  "Liver",
  "Liver Fibrosis",
  "Lymphoma",
  "Lymphoma Diagnosis",
  "Male Infertility",
  "Malnutrition",
  "Meningitis",
  "Mennorhagia",
  "Metabolic Disorder",
  "Metabolic Disorders",
  "Metal Poisoning",
  "Metal Toxicity",
  "Micronutrinet deficiency",
  "Multiple myeloma",
  "Multiple Sclerosis",
  "Musculoskeletal disorders",
  "Myasthenia Gravis",
  "Myelodysplastic syndrome",
  "Myeloproliferative disorders",
  "Myleodysplastic Syndrome",
  "Neurodegenerative Disorder",
  "Neurologic Disorder",
  "Neurological disorders",
  "Nutritional disorders",
  "Osteoporosis",
  "Ovarian Function",
  "Pancreas Function",
  "Pancreatic Function",
  "Paraneoplastic Syndromes",
  "Parasitic Infections",
  "Paroxysmal Nocturnal Hemoglobinuria",
  "Pemphigus",
  "Pernicious Anemia",
  "Pharmacogenomics",
  "Phenol Poisoning",
  "Phenylketonuria",
  "Pituitary Adenoma",
  "Pituitary gland Function",
  "Plasma Cell tumors",
  "Pneumonia",
  "Poisoning - Insecticide",
  "Polycythemia",
  "Porphyrias",
  "Pre-eclampsia",
  "Pregnancy",
  "Prenatal Diagnosis",
  "Recurrent Abortions",
  "Respiratory Infection",
  "Rheumatoid",
  "Rhuematoid",
  "Sarcoidosis",
  "Scleroderma",
  "Screening for metal toxicity",
  "Sexually Transmitted Diseases",
  "SIADH",
  "Sports medicine",
  "Surgical Pathology",
  "Synovial Sarcoma",
  "Syphilis",
  "Therapeutic Drug Monitoring",
  "Thromboembolic disorders",
  "Thrombotic Disorders",
  "Thyroid Function",
  "Thyroid gland Function",
  "Trace Elements",
  "Transplant",
  "Trauma",
  "Tuberculosis",
  "Typhoid",
  "Vascular Tumors",
  "Vitamin Assays",
  "Vitamin B12 Deficiency",
  "Von Willebrand Disease",
  "Whooping Cough",
  "Wilson Disease",
];

export const CardData = [
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
  {
    testName: "SWASTHFIT LITE CHECKUP",
    testPrice: 399,
  },
  {
    testName: "FEVER PANEL 1",
    testPrice: 545,
  },
  {
    testName: "DIABETES PANEL BASIC",
    testPrice: 370,
  },
  {
    testName: "CULTURE, AEROBIC, BLOOD, RAPID",
    testPrice: 1160,
  },
  {
    testName: "LDH; LACTATE DEHYDROGENASE",
    testPrice: 350,
  },
  {
    testName: "BUN; BLOOD UREA NITROGEN",
    testPrice: 140,
  },
  {
    testName: "VITAMIN D, ULTRASENSITIVE",
    testPrice: 2000,
  },
  {
    testName: "MAGNESIUM, SERUM",
    testPrice: 530,
  },
  {
    testName: "CPK; CREATINE KINASE",
    testPrice: 300,
  },
  {
    testName: "TISSUE TRANSGLUTAMINASE (tTG) ANTIBODY, IgA",
    testPrice: 1000,
  },
  {
    testName: "TESTOSTERONE PANEL, TOTAL & FREE",
    testPrice: 2600,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA/ANF), EIA",
    testPrice: 700,
  },
  {
    testName: "NT-PRO BNP (N-TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)",
    testPrice: 3700,
  },
  {
    testName: "FOLATE (FOLIC ACID), SERUM",
    testPrice: 1000,
  },
  {
    testName: "TACROLIMUS; FK506",
    testPrice: 3900,
  },
  {
    testName: "MEASLES (RUBEOLA) ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "SARS-COV-2 (COVID-19) IGG ANTIBODY",
    testPrice: 600,
  },
  {
    testName: "MICROALBUMIN, 1ST MORNING / RANDOM URINE",
    testPrice: 600,
  },
  {
    testName: "MATERNAL SERUM SCREEN 2; DUAL TEST",
    testPrice: 2400,
  },
  {
    testName: "HLA - B27, QUALITATIVE PCR",
    testPrice: 3200,
  },
  {
    testName: "BILE ACID TOTAL, SERUM",
    testPrice: 1500,
  },
  {
    testName: "PREGNANCY TEST, URINE",
    testPrice: 180,
  },
  {
    testName: "BILIRUBIN TOTAL, DIRECT & INDIRECT",
    testPrice: 220,
  },
  {
    testName: "INSULIN, FASTING (F) & POST PRANDIAL (PP), PANEL",
    testPrice: 1550,
  },
  {
    testName: "T3, FREE; FT3",
    testPrice: 370,
  },
  {
    testName: "MUMPS VIRUS ANTIBODY, IgG",
    testPrice: 1800,
  },
  {
    testName: "AFP (ALPHA FETOPROTEIN), TUMOR MARKER",
    testPrice: 900,
  },
  {
    testName: "CEA; CARCINO EMBRYONIC ANTIGEN",
    testPrice: 640,
  },
  {
    testName: "ANTI NUCLEAR ANTIBODY / FACTOR (ANA / ANF), IFA (HEP-2)",
    testPrice: 2400,
  },
  {
    testName: "SUGAR BASIC",
    testPrice: 1300,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIBODY; ANTI HBs",
    testPrice: 730,
  },
  {
    testName: "BICARBONATE, SERUM",
    testPrice: 660,
  },
  {
    testName: "CA 19.9; PANCREATIC CANCER MARKER",
    testPrice: 1500,
  },
  {
    testName: "FECAL CALPROTECTIN",
    testPrice: 3300,
  },
  {
    testName: "SWASTHFIT PRO 4",
    testPrice: 4950,
  },
  {
    testName: "PSA PROFILE",
    testPrice: 1450,
  },
  {
    testName: "PCOD (POLYCYSTIC OVARIAN DISEASE) COMPREHENSIVE PANEL",
    testPrice: 4000,
  },
  {
    testName: "PHOSPHORUS, SERUM",
    testPrice: 160,
  },
  {
    testName: "ABSOLUTE EOSINOPHIL COUNT; AEC",
    testPrice: 160,
  },
  {
    testName: "FSH; FOLLICLE STIMULATING HORMONE",
    testPrice: 500,
  },
  {
    testName: "HOMOCYSTEINE, QUANTITATIVE, SERUM",
    testPrice: 1000,
  },
  {
    testName: "LITHIUM",
    testPrice: 330,
  },
  {
    testName: "LDL CHOLESTEROL, DIRECT",
    testPrice: 340,
  },
  {
    testName: "HEPATITIS B SURFACE ANTIGEN (HBsAg), QUANTITATIVE",
    testPrice: 1320,
  },
  {
    testName: "INTERLEUKIN-6; (IL-6)",
    testPrice: 2400,
  },
  {
    testName: "BILIRUBIN, TOTAL",
    testPrice: 140,
  },
  {
    testName: "ALBUMIN, SERUM",
    testPrice: 140,
  },
  {
    testName: "SUGAR BASIC PANEL",
    testPrice: 1299,
  },
  {
    testName: "CALCIUM, TOTAL & IONIZED",
    testPrice: 700,
  },
  {
    testName: "IRON STUDIES MONITORING PANEL",
    testPrice: 1200,
  },
];
