import React from 'react'
import PartnerOverview from '../Components/partnerOverview'
import Header from '../Components/Header'

const BusinessPartneship = () => {
  return (
    <>
       <Header/>
       <PartnerOverview/>

    </>
  )
}

export default BusinessPartneship
